import React from 'react'

type Props = {
  children: React.ReactNode
}

const Card: React.VFC<Props> = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        {props.children}
      </div>
    </div>
  )
}

export default Card