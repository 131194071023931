import React, { useState, useRef } from 'react'
import { useMutation, gql } from '@apollo/client'
import LoadingComponents from '../loading'

const UPLOAD_FILE = gql(`
mutation Upload($file: Upload!) {
  singleUpload(file: $file) {
    name
    email
    nickname
    password
    user_metadata {
      company_code
      outhouse_terms
    }
    email_verified
  } 
}
`)

const SAVE = gql(`
mutation Save($userInput: [UserInput]!) {
  save(users: $userInput) {
    users {
      name
      email
      nickname
      password
      user_metadata {
        company_code
        outhouse_terms
      }
      email_verified
      error
    }
  }
}
`)

type Props = {
  uploadResult: Function
  users: any[]
  updateUsers: Function
  onClear: Function
}

const Form: React.VFC<Props> = (props) => {
  const [file, setFile] = useState<File>()
  const [loading, setLoading] = useState(Boolean)
  const [isSend, setIsSend] = useState(false)

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onCompleted: data => {
      props.uploadResult(data.singleUpload)
      setLoading(false)
    },
    onError: (err) => {
      console.log(err);
      setLoading(false)
    },
  })

  const [saveMutation] = useMutation(SAVE, {
    onCompleted: (data) => {
      props.updateUsers(data.save.users)
      setLoading(false)
      setIsSend(true)
    },
    onError: (err) => {
      console.log(err);
      setLoading(false)
      setIsSend(true)
    }
  })

  const handlerFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files === null || e.target.files.length === 0) {
      return
    }
    setFile(e.target.files[0])
  }
  const fn = props.users.length <= 0 ? () => {
    return uploadFile({
      variables: { file }
    })
  } : () => {
    const users = props.users.map(user => ({
      name: user.name,
      email: user.email,
      nickname: user.nickname,
      password: user.password,
      user_metadata: {
        company_code: user.user_metadata.company_code,
        outhouse_terms: user.user_metadata.outhouse_terms
      },
      email_verified: user.email_verified
    }))
    return saveMutation({
      variables: {
        userInput: users
      }
    })
  }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setLoading(true)
    fn()
  }

  const fileRef = useRef<HTMLInputElement>(null)
  const onClear = () => {
    if (fileRef && fileRef.current) {
      const current = fileRef.current as HTMLInputElement
      current.value = ''
    }
    props.updateUsers([])
    setFile(undefined)
    setIsSend(false)
    props.onClear()
  }

  return (
    <form onSubmit={onSubmit}>
      {loading && (<LoadingComponents></LoadingComponents>)}
      <div className="row">
        <label className="offset-sm-1 col-sm-2 form-label" htmlFor="formFile">
          Auth0ユーザー情報Excel
        </label>
        <div className="col-sm-8">
          <input ref={fileRef} className="form-control" type="file" onChange={handlerFileChange}></input>
        </div>
      </div>
      <div className="row">
        <div className="offset-sm-1 col-sm-10">
          <div className="d-flex justify-content-end mt-2">
            <button className={`mx-1 btn ${props.users.length > 0 ? 'btn-outline-success' : 'btn-primary'}`} type="submit" disabled={isSend || !(file || props.users.length > 0)}>
              {props.users.length > 0 ? '送信' : '読込'}
            </button>
            <button className={`btn btn-outline-danger`} type="button" onClick={onClear}>クリア</button>
          </div>
        </div>
      </div>
    </form>
  )
}


export default Form
