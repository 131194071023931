import React from "react";

type Props = {
    children: React.ReactNode
}

const TBody: React.VFC<Props> = (props) => {
    return (
        <tbody>
            {props.children}
        </tbody>
    )
}

export default TBody