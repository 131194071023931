import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import './index.css'

type Props = {
  title: string
}

const NavBar: React.VFC<Props> = (props) => {
  const { isAuthenticated, logout } = useAuth0()

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-indigo-6">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">{props.title}</a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
          {isAuthenticated &&
            <div className="d-flex">
              <button className="btn btn-outline-light" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
            </div>
          }
        </div>
      </div>
    </nav>
  )
}

export default NavBar