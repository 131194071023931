import React from 'react'
import './index.css'
import Table from '../table'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

type UserDetailProps = {
  index: number
  companyCode: string
  email: string
  nickname: string
  password: string
  error: string
  emailVerified: boolean
  isSend: boolean
}

const UserDetailComponent: React.VFC<UserDetailProps> = (props) => {
  return (
    <>
      <tr>
        <th scope="row">
          {props.isSend && props.error ?
            (<span data-bs-toggle="collapse" data-bs-target={`#error${props.index}`} ><FontAwesomeIcon className="icon-button text-danger" icon={faCircleExclamation} /> </span>)
            :
            props.isSend ? (<span ><FontAwesomeIcon className="text-success" icon={faCheckCircle} /> </span>) :
              (<span>{props.index} </span>)
          }
        </th>
        <td>{props.companyCode}</td>
        <td>{props.email}</td>
        <td>{props.nickname}</td>
        <td>{props.password}</td>
        <td>{props.emailVerified}</td>
      </tr>
      {
        (<tr>
          <td colSpan={5} className="p-0">
            <div className="collapse" id={`error${props.index}`}>
              {props.error}
            </div>
          </td>
        </tr>)
      }
    </>
  )
}

type userMetadata = {
  company_code: string
  outhouse_terms: string
}

type User = {
  name: string
  email: string
  nickname: string
  password: string
  user_metadata: userMetadata
  email_verified: boolean
  error: string
}

type UserProps = {
  users: User[]
  isSend: boolean
}

const Users: React.VFC<UserProps> = (props) => {
  return (
    <div className="card user-card">
      <div className="card-body">
        <Table className="table">
          <React.Fragment key="thead">
            <tr>
              <th>#</th>
              <th>会社コード</th>
              <th>Email</th>
              <th>Nickname</th>
              <th>Password</th>
              <th>メール送信</th>
            </tr>
          </React.Fragment>
          <React.Fragment key="tbody">
            {props.users.map((user, index) => {
              return <UserDetailComponent emailVerified={user.email_verified} isSend={props.isSend} index={index + 1} companyCode={user.user_metadata.company_code} email={user.email} nickname={user.nickname} password={user.password} error={user.error} key={index}></UserDetailComponent>
            })}
          </React.Fragment>
        </Table>
      </div>
    </div>
  )
}

export default Users
