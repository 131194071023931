import React from 'react'

type Props = {
    children: React.ReactNode
}

const THead: React.VFC<Props> = (props) => {
    return (
        <thead>
            {props.children}
        </thead>
    )
}

export default THead
