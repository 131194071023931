import React from 'react'
import './index.css'

type Props = {}

const Loading: React.VFC<Props> = (props) => {
  return (
    <div className="loading">
      <div className="spinner d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  )
}

export default Loading