import { useState } from 'react'
import './App.css';
import UserComponents from './components/users'
import FormComponents from './components/form'
import CardComponents from './components/cards'
import NavBarComponents from './components/navbar'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
import { useAuth0 } from '@auth0/auth0-react'

function App() {
  const [users, setUsers] = useState([])
  const [isSend, setIsSend] = useState(false)

  const { isLoading, getIdTokenClaims } = useAuth0()
  const authLink = setContext(async (_, { headers }) => {
    const idToken = await getIdTokenClaims()
    return {
      headers: {
        ...headers,
        authorization: idToken && idToken.__raw ? `Bearer ${idToken.__raw}` : ''
      }
    }
  })

  const xHeader = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        "X-Requested-With": window.location.origin
      }
    }
  })

  const client = new ApolloClient({
    link: authLink.concat(xHeader.concat(createUploadLink({ uri: `/graphql` }))),
    cache: new InMemoryCache()
  })


  if (isLoading) {
    return <div>Loading...</div>
  }

  const uploadResult = (data: any) => {
    setUsers(data)
  }

  const saveResult = (data: any) => {
    setUsers(data)
    setIsSend(true)
  }

  const onClear = () => {
    setIsSend(false)
  }

  return (
    <ApolloProvider client={client}>
      <NavBarComponents title="光昭Directユーザー登録"></NavBarComponents>
      <div className="container-xxl my-md-4">
        <CardComponents>
          <FormComponents uploadResult={uploadResult} users={users} updateUsers={saveResult} onClear={onClear}></FormComponents>
          <UserComponents users={users} isSend={isSend}></UserComponents>
        </CardComponents>
      </div>
    </ApolloProvider>
  );
}

export default App;
