import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react'
import ProtectedRouter from './components/protected'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

const domain = process.env.REACT_APP_AUTH0_DOMAIN || '',
  clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
    >
      <Routes>
        <Route path="/*" element={<ProtectedRouter element={App} />}></Route>
      </Routes>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
