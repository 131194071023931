import React from 'react'
import THead from './thead'
import TBody from './tbody'


type Props = {
  children: React.ReactNode
  className: string
}

const Table: React.VFC<Props> = (props) => {
  const filter = (fn: any) => props.children ? Array.isArray(props.children) ? props.children.filter(fn) : [props.children].filter(fn) : null
  const head = filter((x: any) => x.key === "thead")
  const body = filter((x: any) => x.key === "tbody")
  return (
    <table className={props.className}>
      <THead>
        {head}
      </THead>
      <TBody>
        {body}
      </TBody>
    </table>
  )
}

export default Table